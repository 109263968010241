@tailwind base;
@tailwind components;
@tailwind utilities;


.column-webkit {
  -webkit-column-count: 3;
  -webkit-column-gap: 40px;
  -webkit-column-rule: 1px solid #ccc;
}

.column-webkit::-webkit-scrollbar {
  width: 2px;
  cursor: pointer;

}

.column-webkit::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  margin: 3px;
  cursor: pointer;
}

.column-webkit::-webkit-scrollbar-thumb {
  border-radius: 10px;

  cursor: pointer;
    background: hsl(var(--p));
}

.container-webkit {
  -webkit-column-count: 3;
  -webkit-column-gap: 10px;
  -webkit-column-rule: 1px solid #ccc;

}

.color_pick_item {
    background: transparent !important;
    box-shadow: none !important;
    display: block;
}

.container-webkit::-webkit-scrollbar {
  width: 2px;
  height: 9px;
  cursor: pointer;
}

.container-webkit::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.container-webkit::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: hsl(var(--p));
  cursor: pointer;
}
